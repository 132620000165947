import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import CheckoutSteps from '../components/CheckoutSteps';

/**
 * Shipping Address Screen component.
 *
 * @return {JSX.Element} The Shipping Address Screen component
 */
export default function ShippingAddressScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    userInfo,
    cart: { shippingAddress },
  } = state;
  const [fullName, setFullName] = useState(shippingAddress.fullName || '');
  const [mobileNumber, setMobileNumber] = useState(
    shippingAddress.mobileNumber || ''
  );
  const [streetName, setStreetName] = useState(
    shippingAddress.streetName || ''
  );
  const [buildingNumber, setBuildingNumber] = useState(
    shippingAddress.buildingNumber || ''
  );
  const [apartmentNumber, setApartmentNumber] = useState(
    shippingAddress.apartmentNumber || ''
  );
  const [area, setArea] = useState(shippingAddress.area || '');
  const [city, setCity] = useState(shippingAddress.city || '');

  useEffect(() => {
    if (!userInfo) {
      navigate('/signin?redirect=/shipping');
    }
  }, [userInfo, navigate]);
  const submitHandler = (e) => {
    e.preventDefault();
    const mobileNumberPattern = /^01\d{9}$/;
    if (!mobileNumberPattern.test(mobileNumber)) {
      toast.error('Invalid Mobile Number');
      return; // Stop form submission if the mobile number is invalid
    }
    ctxDispatch({
      type: 'SAVE_SHIPPING_ADDRESS',
      payload: {
        fullName,
        mobileNumber,
        streetName,
        buildingNumber,
        apartmentNumber,
        area,
        city,
      },
    });
    localStorage.setItem(
      'shippingAddress',
      JSON.stringify({
        fullName,
        mobileNumber,
        streetName,
        buildingNumber,
        apartmentNumber,
        area,
        city,
      })
    );
    navigate('/payment');
  };
  return (
    <div>
      <Helmet>
        <title>Shipping Address</title>
      </Helmet>

      <CheckoutSteps step1 step2></CheckoutSteps>
      <div className="container small-container">
        <h1 className="my-3">Shipping Address</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="fullName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="mobileNumber">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="streetName">
            <Form.Label>Street Name</Form.Label>
            <Form.Control
              value={streetName}
              onChange={(e) => setStreetName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="buildingNumber">
            <Form.Label>Building Number</Form.Label>
            <Form.Control
              value={buildingNumber}
              onChange={(e) => setBuildingNumber(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="apartmentNumber">
            <Form.Label>Apartment Number</Form.Label>
            <Form.Control
              value={apartmentNumber}
              onChange={(e) => setApartmentNumber(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="area">
            <Form.Label>Area</Form.Label>
            <Form.Control
              value={area}
              onChange={(e) => setArea(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Select
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            >
              <option value="">Select City</option>
              <option value="Alexandria">Alexandria</option>
              <option value="Cairo">Cairo</option>
              <option value="Giza">Giza</option>
            </Form.Select>
          </Form.Group>
          <div className="mb-3">
            <Button variant="primary" type="submit">
              Continue
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

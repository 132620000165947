// import React, { useContext } from 'react';
import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
// import { Bag } from 'react-bootstrap-icons'; // Import the Bag icon from react-bootstrap-icons
// import Rating from './Rating';
// import axios from 'axios';
// import { Store } from '../Store';

function Product(props) {
  const { product } = props;

  // const { state, dispatch: ctxDispatch } = useContext(Store);
  // const {
  //   cart: { cartItems },
  // } = state;

  // const addToCartHandler = async (item) => {
  //   const existItem = cartItems.find((x) => x._id === product._id);
  //   const quantity = existItem ? existItem.quantity + 1 : 1;
  //   const { data } = await axios.get(`/api/products/${item._id}`);
  //   if (data.countInStock < quantity) {
  //     window.alert('Sorry. Product is out of stock');
  //     return;
  //   }
  //   ctxDispatch({
  //     type: 'CART_ADD_ITEM',
  //     payload: { ...item, quantity },
  //   });
  // };

  return (
    <Card>
      <Card.Body className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
        <Link to={`/product/${product.slug}`}>
        <div className="image-container">
            <img
              src={product.image[0]}
              className="card-img-top rounded"
              alt={product.name}
            />
          </div>
        </Link>
        <div className="mt-2">
          <Link to={`/product/${product.slug}`}>
            <Card.Title>{product.name}</Card.Title>
          </Link>
          {/* <Rating rating={product.rating} numReviews={product.numReviews} /> */}
          <Card.Text>{product.price} EGP</Card.Text>
{/*           
          {product.countInStock === 0 ? (
          <Button variant="light" disabled>
            Out of stock
          </Button>
        ) : (
          <Button onClick={() => addToCartHandler(product)}>
            <Bag size={30} />
          </Button>
        )} */}
        </div>
      </Card.Body>
    </Card>
  );
}

export default Product;
